import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import {ILoginState, ILoginActions, IUserProfile} from './login.types'
import tokenService from '../../services/token.service'
import { auth, checkAuth, getUserProfile, logout } from './login.api'
import { resetStore } from '../reset.store'


export const initialStateLogin: ILoginState = {
    error: null,
    isLoading: false,
    isAuthorized: false,

    profile: {} as IUserProfile,
}

/** Хранилище страницы - Авторизация */
export const useLogin = create<ILoginState & ILoginActions>()(devtools((set) => ({
    ...initialStateLogin,

    /** Авторизация в системе */
    actionLogin: async (login: string, password: string) => {
        set({isLoading: true})

        const res = await auth(login, password)
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK' && res.data?.token && res.data.tokenExpiration) {
            localStorage.setItem('us_id', res.data?.token)
            localStorage.setItem('us_exp', res.data.tokenExpiration.toString())

            tokenService.refreshToken(res.data.tokenExpiration)

            set({error: null})
            set({isAuthorized: true})
        }

        set({isLoading: false})
    },

    /** Проверка авторизации пользователя */
    actionCheckAuth: async () => {
        set({isLoading: true})

        const res = await checkAuth()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK' && res.data?.token && res.data.tokenExpiration) {
            localStorage.setItem('us_id', res.data?.token)
            localStorage.setItem('us_exp', res.data.tokenExpiration.toString())

            tokenService.refreshToken(res.data.tokenExpiration)

            set({error: null})
            set({isAuthorized: true})

            return true
        }

        set({isLoading: false})
        return false
    },

    actionLogout: async () => {
        tokenService.abortRefreshToken()
        localStorage.removeItem('us_id')
        localStorage.removeItem('us_exp')
        resetStore()

        await logout()
    },

    actionGetUserProfile: async () => {
        set({isLoading: true})

        const res = await getUserProfile()
        if (res.status !== 200) set({ error: {type: res.status === 500 ? 'error' : 'warning', message: res.message} })

        if (res.message === 'OK') {
            set({error: null})
            set({isAuthorized: true})
            set({profile: res.data!})
        }

        set({isLoading: false})
    },

}), {name: 'loginState'}))